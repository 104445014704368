@import '../../common/styles/CommonVariables.module.css';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay_background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalBox {
  background: var(--box_background_color);
  padding: var(--padding);
  border-radius: var(--border_radius);
  width: 450px;
  box-shadow: var(--shadow);
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

.modalHeading {
  font-family: var(--font_family);
  font-size: var(--font_size);
  color: var(--font_color);
  font-weight: bold;  
  margin-bottom: calc(var(--margin) - 10px);
}

.modalContent {
  font-family: var(--font_family);
  font-size: var(--font_size);
  color: var(--font_color);
  margin-bottom: var(--margin);
}

.modalActions {
  display: flex;
  justify-content: space-around;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);

  }
}