@import '../../../common/styles/CommonVariables.module.css';

.divider_line {
    border-top: var(--border);
    margin-top: var(--margin);
    margin-bottom: var(--margin);
}

/* Container for the delivery zone box */
.delivery_zone_clickable_box {
    display: flex;
    position: relative;
    border: var(--border);
    border-radius: var(--border_radius);
    justify-content: space-between;
    padding: var(--half_margin);
}

.delivery_zone_clickable_box:hover {
    background-color: var(--light_grey_color);
}

/* Adjusted arrow styles */
.arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transition: transform 0.3s;
    align-self: center;
    /* Ensure the arrow is centered */
}

.arrow.down {
    border-top: 10px solid black;
}

.arrow.up {
    border-bottom: 10px solid black;
}

/* Styling for the delivery zone name */
.delivery_zone_name {
    font-family: var(--font_family);
    font-size: var(--font_size);
    color: var(--font_color);
}

.base_price_text{
    font-family: var(--font_family);
    font-size: var(--font_size);
    color: var(--font_color);
    font-weight: bold;
    margin-bottom: var(--half_margin);
}

/* Hidden zone details by default */
.zone_details_box {
    border: var(--border);
    border-radius: var(--border_radius);
    padding: var(--padding);
    margin-top: var(--half_margin);
    display: none;
}

/* Display zone details when the zone is expanded */
.delivery_zone_clickable_box.expanded +.zone_details_box {
    display: block;
}