@import '../../common/styles/CommonVariables.module.css';

.pricing_plan_box {
    border: var(--border);
    /* Darker border for contrast */
    background-color: var(--box_background_color);
    /* White background to stand out */
    border-radius: var(--border_radius);
    padding: var(--padding);
    margin-bottom: var(--margin);
    box-shadow: var(--shadow);
}

/* Header styling for the pricing plan box */
.pricing_plan_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--margin);
}

/* Name of the pricing plan */
.pricing_plan_name {
    font-size: var(--font_size);
    font-family: var(--font_family);
    color: var(--font_color);
    font-weight: bold;
}

/* Divider between heading and form */
.heading_form_divider {
    border-top: var(--border);
    margin-bottom: var(--margin);
}

.required_text {
    font-size: 0.8em; /* Smaller font size */
    font-style: italic; /* Italic font */
    font-family: var(--font_family);
    color: var(--red_color);
    margin-left: 5px; /* Optional: space between heading and required text */
}

.pricing_plan_box:last-child{
    margin-bottom: 0px;
}