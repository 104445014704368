/* LoadingSpinner.css */

@import './CommonVariables.module.css';

/* Fullscreen overlay with blur effect */
.loading_overlay {
  position: fixed;
  /* Fix the overlay in place */
  top: 0;
  /* Align to the top */
  left: 0;
  /* Align to the left */
  width: 100vw;
  /* Full viewport width */
  height: 100vh;
  /* Full viewport height */
  background: var(--overlay_background);
  /* Semi-transparent background */
  backdrop-filter: blur(5px);
  /* Blur effect */
  display: flex;
  /* Center spinner horizontally and vertically */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  z-index: 9999;
  /* Ensure it's on top of other elements */
  pointer-events: all;
  /* Disable interaction with background */
}

/* Styling for the spinner container */
.loading_spinner {
  display: flex;
  flex-direction: column;
  /* Stack spinner and text vertically */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  width: 150px;
  /* Width of the spinner container */
  height: 150px;
  /* Height of the spinner container */
  background-color: var(--page_background_color);
  /* Slight white background */
  border-radius: var(--border_radius);
  /* Rounded corners */
  box-shadow: var(--box_shadow);
  /* Shadow effect */
}

/* Styling for the spinner itself */
.spinner {
  border: 8px solid #f3f3f3;
  /* Light grey background */
  border-top: 8px solid #3498db;
  /* Blue color for the spinning part */
  border-radius: 50%;
  /* Circular spinner */
  width: 50px;
  /* Width of the spinner */
  height: 50px;
  /* Height of the spinner */
  animation: spin 1.5s linear infinite;
  /* Spin animation */
}

/* Keyframes for the spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Styling for the loading message */
.loading_spinner p {
  margin-top: 20px;
  /* Space between spinner and text */
  font-size: var(--font_size);
  /* Font size for the loading message */
  font-family: var(--font_family);
  color: var(--font_color);
  /* Color of the loading message text */
}