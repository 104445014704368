@import '../../common/styles/CommonVariables.module.css';

/* Form group styling */
.form_group {
    margin-bottom: var(--margin);
}

.form_group label {
    display: block;
    font-family: var(--font_family);
    font-size: var(--font_size);
    color: var(--font_color);
}

.form_control {
    width: 100%;
    padding: 8px;
    border: var(--border);
    border-radius: 5px;
    font-family: var(--form_font_family);
    font-size: var(--form_font_size);
    color: var(--form_font_color);
}

/* Error message styling */
.error_message {
    color: var(--red_color);
    font-size: var(--font_size);  
    font-style: italic; 
    font-family: var(--font_family);
}

.submitted_data_box {
    background-color: var(--light_grey_color);
    padding: var(--padding);
    border-radius: var(--border_radius);
    margin-top: var(--margin);
}

/* Header for the submitted data box */
.submitted_data_heading {
    margin-bottom: var(--half_margin);
    font-family: var(--font_family);
    font-size: var(--font_size);
    color: var(--font_color);
}
.submitted_data_text{
    font-family: var(--font_family);
    font-size: var(--font_size);
    color: var(--font_color);
}


.submitted_content{
    margin-bottom: var(--margin);
}

.submitted_content:last-child{
    margin-bottom: 0px;
}