@import '../../common/styles/CommonVariables.module.css';

/* Table Container */
.tableContainer {
    margin-bottom: 20px;
    max-width: 100%; /* Ensure the container width is responsive */
    width: 100%; /* Allow the container to adapt to screen size */
    overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
    display: block; /* Ensure it acts as a block-level container */
    white-space: nowrap; /* Prevent breaking rows into multiple lines */
}

/* Table Styling */
.data_table {
    width: 100%; /* Table width adapts to the container */
    border-collapse: collapse;
    color: var(--text_color);
    font-family: var(--font_family);
    font-size: var(--font_size);
    table-layout: auto; /* Automatically adjusts column width based on content */
}

/* Sticky Table Header */
.data_table thead {
    position: sticky;
    top: 0;
    background-color: var(--light_grey_color);
    font-weight: bolder;
    z-index: 2;
}

/* Table Header Cells */
.data_table thead tr th {
    padding: 10px;
    text-align: center; /* Center the header text */
    vertical-align: middle;
    white-space: nowrap; /* Prevent header wrapping */
    overflow: hidden; /* Prevent overflow of long header content */
    text-overflow: ellipsis; /* Add ellipsis for long headers */
}

/* Table Rows */
.data_table tbody tr td {
    padding: 10px;
    vertical-align: middle;
    text-align: center; /* Center align data */
    word-wrap: break-word; /* Allow content to wrap within cells */
    white-space: normal; /* Ensure content wraps naturally */
    overflow-wrap: break-word; /* Break long content like emails or URLs */
}

/* Email Column Wrapping */
.data_table td.email_column {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center; /* Center email addresses */
}

/* Alternating Row Colors */
.data_table tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

.data_table tbody tr:nth-child(even) {
    background-color: #f0f0f0;
}

/* Total Cell Styling */
.totalCell {
    color: var(--text_color);
    font-family: var(--font_family);
    font-size: var(--font_size);
    text-align: center;
    background-color: var(--light_grey_color);
    font-weight: bolder;
}

/* Invoice # Clickable Link Styling */
.data_table td:nth-child(1) a {
    color: #007bff;
    font-weight: bold;
    cursor: pointer;
}

.data_table td:nth-child(1) a:hover {
    text-decoration: underline;
    color: #007bff;
}
