@import '../../common/styles/CommonVariables.module.css';

.container {
    display: flex;
    flex-direction: column;
    margin-top: var(--margin);
}

.cityHeader {
    font-weight: bold;
    font-family: var(--font_family);
    font-size: var(--font_size);
    margin-bottom: var(--half_margin);
    color: var(--font_color);
}

.cityList {
    display: flex;
    flex-wrap: wrap; /* Allow tags to wrap to the next line */
    gap: 2.5px; /* Space between tags */
}

.cityNames {
    display: inline-block;
    background-color: var(--city_background_color); /* Light background for tags */
    font-family: var(--font_family);
    font-size: var(--city_font_size);
    color: var(--city_font_color);
    text-align: center; /* Center text inside the tag */
    white-space: nowrap; /* Prevent text wrapping */
    border-radius: var(--city_border_radius); /* Rounded corners */
    padding: 5px 10px; /* Spacing inside the tag */    
}
