@import '../../common/styles/CommonVariables.module.css';

.date {
    display: flex;
    /* Aligns the text and input in a row */
    align-items: center;
    /* Vertically centers the text and input */
    margin-bottom: var(--margin);
    /* Adds space below the entire input field */
}

.date label {
    font-family: var(--font_family);
    font-size: var(--font_size);
    color: var(--font_color);
}

.date input {
    padding: 5px;
    /* Adds padding inside the input for better spacing */
    font-family: var(--font_family);
    font-size: var(--font_size);
    color: var(--font_color);
    border-radius: 5px;
    /* Rounds the corners of the input */
    border: 1px solid #ccc;
    /* Adds a light border around the input */
    margin-left: 2.5px;
    /* Adds space between the label text and the input */
    width: 125px;
    /* Sets a fixed width for the input */
    text-align: left;
    /* Aligns the text to the left in the input */
}