/* PageLayout.module.css */

@import './CommonVariables.module.css';

.page_container {
    border: var(--border);
    border-radius: var(--border_radius);
    background-color: var(--page_background_color);
    width: 100%;
    padding: var(--padding);
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
}