/* CommonVariables.module.css */
:root {
    /* Page Level Variables */
    --page_background_color: #f9f9f9;

    /* Inside Page Box Variables */
    --box_background_color: #ffffff;

    /* Button Specific Variables */
    --button_text_color: #ffffff;
    --button_border_radius: 5px;
    --button_padding: 10px 20px;
    --button_font_size: 15px;
    --button_font_family: 'Arial', sans-serif;
    --button_font_weight: bold; 
    
    /* City Specific Variables */
    --city_background_color: #f0f0f0;
    --city_font_size: 12.5px;
    --city_border_radius: 12px;
    --city_font_color: #333333;

    /* Form specific variables */
    --form_font_color: black;
    --form_font_size: 14px;
    --form_font_family: 'Arial', sans-serif;

    /* Default Variables */
    --border_radius: 10px;
    --font_color: black;
    --font_size: 15px;
    --font_family: 'Arial', sans-serif;
    --shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --border: 1px solid #cccccc;
    --padding: 20px;
    --half_padding: 10px;
    --margin: 20px;
    --half_margin: 10px;
    --overlay_background: rgba(0, 0, 0, 0.3);
    --light_grey_color: #cccccc;
    --grey_color: #4A5568;
    --dark_grey_color: #2D3748;
    --teal_color: #319795;
    --dark_teal_color: #2c7a7b;
    --light_red_color: #fff8f8;
    --red_color: #e53e3e;
    --dark_red_color: #c53030;
    --white_color: #ffffff;
    --black_color: black;
    --blue_color: #485fc7;
    --dark_blue_color: #3d4db3;
}