@import '../../../common/styles/CommonVariables.module.css';

/* Container for the pricing plan box */
.pricing_plan_box {
    border: var(--border);
    background-color: var(--box_background_color); /* White background to stand out */
    border-radius: var(--border_radius);
    padding: var(--padding);
    margin-bottom: var(--margin);
    box-shadow: var(--shadow);
}

.pricing_plan_box:last-child {
    margin-bottom: 0px;
}