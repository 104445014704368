@import '../../common/styles/CommonVariables.module.css';

.pricing_plan_box {
    border: var(--border);
    /* Darker border for contrast */
    background-color: var(--box_background_color);
    /* White background to stand out */
    border-radius: var(--border_radius);
    padding: var(--padding);
    box-shadow: var(--shadow);
}