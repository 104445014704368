@import '../../common/styles/CommonVariables.module.css';

.error_box {
    border: 1px solid var(--red_color);
    /* Red border */
    background-color: var(--light_red_color);
    /* Light red background */
    padding: var(--half_padding) var(--padding);
    border-radius: var(--border_radius);
    margin-top: var(--margin);
    /* Top and bottom margin of 10px */
}

.error_message {
    color: var(--red_color);
    font-size: var(--font_size);
    font-style: italic;
    font-weight: 400;
    font-family: var(--font_family);
}