@import '../../common/styles/CommonVariables.module.css';


/* Table Container */
.data_table {
    width: 100%;
    table-layout: fixed;
    margin-bottom: var(--margin);  
}

.data_table thead {
    background-color: var(--light_grey_color);
    border-radius: var(--border_radius);
}

.data_table th {
    border-bottom: 2px solid var(--light_grey_color);
}

.data_table thead tr th {
    font-family: var(--font_family);
    font-size: var(--font_size);
    color: var(--text_color);
    padding: 15px;   
    vertical-align: middle;
}

.data_table td {
    border-bottom: 2px solid #f0f0f0;
}

.data_table tbody tr td {
    font-family: var(--font_family);
    font-size: var(--font_size);
    color: var(--text_color);
    padding: 8px 15px;   
    vertical-align: middle;
}

.data_table tbody tr:nth-child(even) {
    background-color: #f0f0f0;
}

.input_field {
    width: 85px;
    padding: 8px;
    border-radius: 5px;
    font-family: var(--font_family);
    font-size: var(--font_size);
    outline: 1px solid var(--light_grey_color);
    border: none;
}

.input_field_error {
    width: 85px;
    padding: 8px;
    border-radius: 5px;
    font-family: var(--font_family);
    font-size: var(--font_size);
    outline: 1px solid var(--red_color);
    border: none;
    background-color: var(--light_red_color);
}
