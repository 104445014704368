@import '../../common/styles/CommonVariables.module.css';

.top_button_layer {
    display: flex;
    gap: 10px;
}

.top_button_layer button {
    flex: 1; /* Ensures all buttons are the same size */
    padding: 10px 20px; /* Controls the space inside the buttons */
    text-align: center; /* Centers text inside buttons */
}

.heading_form_divider {
    border-top: var(--border);
    margin-top: var(--margin);
    margin-bottom: var(--margin);
}