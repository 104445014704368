@import '../../../common/styles/CommonVariables.module.css';

/* Header styling for the pricing plan box */
.pricing_plan_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Name of the pricing plan */
.pricing_plan_name {
    font-size: var(--font_size);
    color: var(--font_color);
    font-weight: bold;
    font-family: var(--font_family);
}

/* Container for the toggle and delete button */
.pricing_plan_actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Container for the toggle switch */
.pricing_plan_toggle {
    display: flex;
    align-items: center;
    min-width: 100px;
}

/* Styling for the toggle switch itself */
.pricing_plan_toggle .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin-right: 2.5px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle_text {
    font-size: var(--font_size);
    color: var(--font_color);
    font-weight: bold;
    font-family: var(--font_family);
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--grey_color);
    transition: .4s;
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    left: 4px;
    bottom: 4px;
    background-color: var(--white_color);
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--teal_color);
}

input:checked + .slider:before {
    transform: translateX(16px);
}

/* Round slider */
.slider_round {
    border-radius: 24px;
}

.slider_round:before {
    border-radius: 50%;
}