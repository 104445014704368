@import './CommonVariables.module.css';

/* General button styles */
.button_base {
    color: var(--button_text_color); /* White */
    border: none;
    border-radius: var(--button_border_radius); /* 5px */
    padding: var(--button_padding); /* 10px 20px */
    font-size: var(--button_font_size); /* 15px */
    font-weight: var(--button_font_weight); /* bold */
    font-family: var(--button_font_family); /* Arial, sans-serif */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
}

.button_base:focus {
    outline: 2px solid transparent;
    outline-offset: 2px; /* Fixed to 2px */
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1); /* Default box-shadow */
    color: var(--button_text_color); /* White */
}

.button_icon {
    display: flex;
    align-items: center;
    padding: 0; /* Remove padding inherited from .button_base */
    color: var(--black_color);
    background: none;
    margin-left: 5px;
    margin-bottom: 10px;
    width: 60px; /* Prevent full-width issue */
    transition: color 0.3s, background 0.3s, outline 0.3s, box-shadow 0.3s, transform 0.2s; /* Added transitions */
    outline: 2px solid transparent; /* Set initial outline to transparent to avoid flash */
}

.button_icon:hover {
    color: var(--black_color); /* Color change on hover */
    background-color: var(--light_grey_color); /* Background color on hover */
    outline: 2px solid var(--light_grey_color); /* Outline color on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

.button_icon:focus {
    color: var(--black_color); /* Color change on focus */
    outline: 2px solid var(--grey_color); /* Outline color on focus */
    background-color: var(--light_grey_color); /* Background color on focus */
}



/* Red button styles */
.button_red {
    background-color: var(--red_color); /* #e53e3e */
}

.button_red:hover {
    background-color: var(--dark_red_color); /* #c53030 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button_red:focus {
    outline-color: var(--red_color); /* #e53e3e */
    box-shadow: 0 0 0 2px rgba(229, 62, 62, 0.4);
}

/* Blue button styles */
.button_blue {
    background-color: var(--blue_color); /* #485fc7 */
}

.button_blue:hover {
    background-color: var(--dark_blue_color); /* #3d4db3 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button_blue:focus {
    outline-color: var(--blue_color); /* #485fc7 */
    box-shadow: 0 0 0 2px rgba(72, 95, 199, 0.4);
}

.icon_spacing {
    margin-right: 5px;
}

/* Teal button styles */
.button_teal {
    background-color: var(--teal_color); /* #319795 */
    transition: background-color 0.3s, box-shadow 0.2s;
}

.button_teal:hover {
    background-color: var(--dark_teal_color); /* #2c7a7b */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button_teal:focus {
    outline-color: var(--teal_color); /* #319795 */
    box-shadow: 0 0 0 2px rgba(49, 151, 149, 0.4);
}

/* Grey button styles */
.button_grey {
    background-color: var(--grey_color); /* #4A5568 */
    transition: background-color 0.3s, box-shadow 0.2s;
}

.button_grey:hover {
    background-color: var(--dark_grey_color); /* #cccccc */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button_grey:focus {
    outline-color: var(--grey_color); /* #4A5568 */
    box-shadow: 0 0 0 2px rgba(0,0,0, 0.4); 
}
